<template>
	<div class="trading-view" id="tv_chart_container"></div>
</template>
<style scoped>
	@media screen and (max-width: 800px) {
		#tv_chart_container {
			width: 100%;
			flex: 1;
			width: 100%;
			height: 280px;
		}
	}

	@media screen and (min-width: 801px) {
		#tv_chart_container {
			width: 100%;
			flex: 1;
			min-width: 618px;
			min-height: 400px;
		}
	}
</style>

<script>
	import {
		widgetOptions
	} from "./datafeed/config";
	export default {
		name: "TradingView",
		props: {
			datafeedUrl: {
				default: "/product/kline",
				type: String
			},
			interval: {
				default: "1",
				type: String
			},
			libraryPath: {
				default: "/charting_library/",
				type: String
			},
			containerId: {
				default: "tv_chart_container",
				type: String
			},
			studies_overrides: {
				type: Object
			},
			chartype: {
				type: Number,
				default: 1,
			},
			setStudies: Function,
			overrides: {
				type: Object,
				default () {
					return {
						"paneProperties.background": "#FFFFFF", // 画板背景
						"paneProperties.vertGridProperties.color": "#d0d0d0", //画板网格纵列 #2a3654
						"paneProperties.horzGridProperties.color": "#d0d0d0" //画板网格横列 #2a3654
					};
				}
			}
			// symbol: {
			//   default:'BTC/USDT',
			//   type: String
			// }
		},
		data() {
			return {
				lang: 'en'
			}
		},
		created() {
			this.lang = this.getCurLang()
		},
		computed: {
			curCoin() {
				return this.$store.state.curCoin
			},
			localCoin() {
				let cur_coin = localStorage.getItem('curCoin')
				if (cur_coin) {
					cur_coin = JSON.parse(cur_coin)
				} else {
					cur_coin = this.curCoin
				}
				return cur_coin
			},
			symbol() {
				return this.curCoin.key || this.localCoin.key || "";
			},
			getPrecision() {
				return this.curCoin.priceDecimal
			}
		},
		methods: {
			getCurLang() {
				let lang = localStorage.lang
				if(lang == 'zh-TW') {
					lang = 'zh_TW'
				} else if(lang == 'zh-CN') {
					lang = 'zh'
				} else if(lang == 'id') {
					lang = 'id_ID'
				} else if(lang == 'hi' || lang == 'uz' || lang == 'az') {
					lang = 'en'
				}
				return lang
			},
				
			createStudy() {
				this.tvWidget.onChartReady(() => {
					const chart = this.tvWidget.chart();
					const colors = ["#ff69b4", "#d8bfd8", "#7b68ee", "#20b2aa"];
					[5, 10, 30, 60].forEach((min, index) => {
						chart.createStudy(
							"Moving Average",
							false,
							false,
							[min, "close"],
							null, {
								"Plot.linewidth": 1,
								"Plot.color": colors[index],
								precision: this.getPrecision
							}
						);
					})
					chart.setChartType(this.chartype)
					// console.log('方法',this.chartype)
					if (this.chartype === 3) {
						setTimeout(() => {
							this.setStudies()
						})
					}
				});
			},
			applyOverrides() {
				this.tvWidget.onChartReady(() => {
					const chart = this.tvWidget.chart();
					const studies = chart.getAllStudies();
					studies.forEach(item => {
						if (item.name !== "Volume" && item.id) {
							const ma = chart.getStudyById(item.id);
							// 设置币种价格精度
							ma.applyOverrides({
								"Plot.linewidth": 1,
								precision: this.getPrecision
							});
						}
					});
				});
			},
			init() {
				// if (window.tvWidget && window.tvWidget._id) {
				//   window.tvWidget.remove();
				//   window.tvWidget = null;
				// }
				this.option = widgetOptions({
					symbol: this.symbol,
					datafeedUrl: this.datafeedUrl,
					containerId: this.containerId,
					libraryPath: this.libraryPath,
					studies_overrides: this.studies_overrides,
					overrides: this.overrides,
					interval: this.interval,
					cssurl: this.libraryPath + "/reset.css",
					lang: this.lang
				});

				const containerId = document.getElementById(this.containerId)
				if (containerId) {
					window.tvWidget = new window.TradingView.widget(this.option);
					this.tvWidget = window.tvWidget;
					this.createStudy();
				}
			}
		},
		watch: {
			// eslint-disable-next-line no-unused-vars
			symbol(n, o) {
				try {
					this.init();
				} catch (error) {
					console.error(error);
				}
			}
			/**
			 * 不需要mounted 调用
			 * symbol: {
			 *    handler(){
			 *      this.init()
			 *    },
			 *    immediate：true
			 * }
			 */
		},
		activated() {
			this.init();
		},
		mounted() {
			this.init();
		},
		destroyed() {
			if (this.tvWidget !== null) {
				this.tvWidget.remove();
				this.tvWidget = null;
			}
		}
	};
</script>